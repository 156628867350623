import Footer from "./Footer";
import Navbar from "./Navbar";


function Header() {

    return ( 
        <div>
            <Navbar />
            <div className="bg-[#FAFAFA] overflow-hidden">
                <div className="">
                    {/* <img className="absolute w-full h-full object-cover" src="/images/bg-pattern.jpg" alt=""/> */}

                    <div className="bg-[#333] pt-32 pb-24 bg-cover bg-center" style={{backgroundImage: "url('/mages/bg-pattern-neuron-2.png') "}} >
                        <div className=" container mx-auto px-4">
                            <div className="grid grid-cols-12 gap-1">
                                <div className="col-span-12 md:col-span-7 flex justify-center items-center">
                                <img src="/images/evtext.png" className="w-48  md:w-auto md:max-w-xs my-4"/>
                                <h1  className="text-3xl md:text-3xl font-bold text-cyan-300 drop-shadow">&nbsp; 🤝 &nbsp;</h1>
                                <img src="/images/cure531.jpg" className="w-48 border-white border-2 md:w-auto md:max-w-xs my-4"/>
                                </div>
                                <div className="col-span-12 md:col-span-4 flex flex-col justify-center drop-shadow-sm" style={{minHeight: "360px"}}>
                                    <div className="slide-left">
                                        <h1 className="text-2xl md:text-3xl font-bold text-white drop-shadow">Charge your security posture with Electrovolt and Cure53.</h1>
                                        <p className="text-gray-100 text-justify text-gray mt-4">
                                        We help secure the applications of clients by providing them world class all-round product assessments, design reviews, and threat-reducing mitigation strategies. Our aim is to foster a long-term relationship with our clients where we can provide continuous support for their requirements. Consider us as one-stop for all your security needs. 
                                        </p>

                                        <div>
                                            <a href="#"><button className="font-medium px-6 py-2  mt-6 border-2 border-cyan-300 text-cyan-300 w-auto hover:bg-cyan-300 hover:text-[#333]">Get started</button></a>
                                        </div>
                                        <span id="about"></span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>   

                    
                </div>

                <div className="w-full container -mt-8 mx-auto px-4 text-center overflow-visible">
                    <div className="bg-white w-full max-w-4xl mx-auto inline-block px-4 py-8 md:p-8">
                        <h2 className="text-2xl md:text-3xl font-bold  text-[#333]"> About Electrovolt 🤝 Cure53 </h2>
                        <span className="inline-block w-24 border-t border-gray-400"></span>
                        <div>
                            <p className="m-4 text-[#666] text-justify max-w-prose inline-block">At Electrovolt, we're committed to providing our clients with the best possible security solutions. That's why we've partnered with Cure53, a world-leading security firm, to offer a range of application security services. Whether you're a small business owner or a large corporation, we have the expertise and experience to protect your digital assets.
<br></br><br></br>
If you're looking for a team who can execute a pentest with the latest threats in mind, look no further than Electrovolt. With our team of hackers and partnership with Cure53, you can trust a well executed pentest with top-notch deliverables. <a href="#contact" className="text-[#333] ">Contact us today!</a></p>
                        </div>
                    </div>

                    <div className="pt-32 text-center" id="services">
                        <h2 className="text-2xl md:text-2xl font-bold uppercase text-[#333]">Our Services</h2>
                        <span className="inline-block w-24 border-t border-gray-400"></span>
                        <div className="grid grid-cols-3 gap-4 md:gap-8 my-4">
                            <div className="col-span-3 md:col-span-1">
                                <div className="md:flex h-full items-center gap-4 p-4">
                                    <div className="opacity-50 text-center mb-4 md:mb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="inline-block" aria-hidden="true" role="img" width="8em" height="8em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656z"/><path fill="currentColor" fill-opacity=".15" d="M184 840h656V184H184v656zm339.5-223h185c4.1 0 7.5 3.6 7.5 8v48c0 4.4-3.4 8-7.5 8h-185c-4.1 0-7.5-3.6-7.5-8v-48c0-4.4 3.4-8 7.5-8zM308 610.3c0-2.3 1.1-4.6 2.9-6.1L420.7 512l-109.8-92.2a7.63 7.63 0 0 1-2.9-6.1V351c0-6.8 7.9-10.5 13.1-6.1l192 160.9c3.9 3.2 3.9 9.1 0 12.3l-192 161c-5.2 4.4-13.1.7-13.1-6.1v-62.7z"/><path fill="currentColor" d="m321.1 679.1l192-161c3.9-3.2 3.9-9.1 0-12.3l-192-160.9A7.95 7.95 0 0 0 308 351v62.7c0 2.4 1 4.6 2.9 6.1L420.7 512l-109.8 92.2a8.1 8.1 0 0 0-2.9 6.1V673c0 6.8 7.9 10.5 13.1 6.1zM516 673c0 4.4 3.4 8 7.5 8h185c4.1 0 7.5-3.6 7.5-8v-48c0-4.4-3.4-8-7.5-8h-185c-4.1 0-7.5 3.6-7.5 8v48z"/></svg>
                                    </div>
                                    <div className="grow text-center md:text-left py-4">
                                        <p className="font-bold text-[#333] mb-2">Code Review</p>
                                        <p className="text-[#666] text-semibold">Manual and automation assisted code review to ensure we provide full coverage to your application.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3 md:col-span-1">
                                <div className="md:flex h-full items-center gap-4 p-4">
                                    <div className="opacity-50 text-center mb-4 md:mb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="inline-block" aria-hidden="true" role="img" width="8em" height="8em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM810 654.3L512 886.5L214 654.3V226.7l298-101.6l298 101.6v427.6z"/><path fill="currentColor" fill-opacity=".15" d="M460.7 451.1a80.1 80.1 0 1 0 160.2 0a80.1 80.1 0 1 0-160.2 0z"/><path fill="currentColor" fill-opacity=".15" d="M214 226.7v427.6l298 232.2l298-232.2V226.7L512 125.1L214 226.7zm428.7 122.5c56.3 56.3 56.3 147.5 0 203.8c-48.5 48.5-123 55.2-178.6 20.1l-77.5 77.5a8.03 8.03 0 0 1-11.3 0l-34-34a8.03 8.03 0 0 1 0-11.3l77.5-77.5c-35.1-55.7-28.4-130.1 20.1-178.6c56.3-56.3 147.5-56.3 203.8 0z"/><path fill="currentColor" d="m418.8 527.8l-77.5 77.5a8.03 8.03 0 0 0 0 11.3l34 34c3.1 3.1 8.2 3.1 11.3 0l77.5-77.5c55.6 35.1 130.1 28.4 178.6-20.1c56.3-56.3 56.3-147.5 0-203.8c-56.3-56.3-147.5-56.3-203.8 0c-48.5 48.5-55.2 122.9-20.1 178.6zm65.4-133.3a80.1 80.1 0 0 1 113.3 0a80.1 80.1 0 0 1 0 113.3c-31.3 31.3-82 31.3-113.3 0s-31.3-82 0-113.3z"/></svg>
                                    </div>
                                    <div className="grow text-center md:text-left py-4">
                                        <p className="font-bold text-[#333] mb-2">Penetration Testing</p>
                                        <p className="text-[#666] text-semibold">Rigorous dynamic pentesting of your assets to ensure that you stay one step ahead from the adversaries.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3 md:col-span-1">
                                <div className="md:flex h-full items-center gap-4 p-4">
                                    <div className="opacity-50 text-center mb-4 md:mb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="inline-block" aria-hidden="true" role="img" width="8em" height="8em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM810 654.3L512 886.5L214 654.3V226.7l298-101.6l298 101.6v427.6z"/><path fill="currentColor" fill-opacity=".15" d="M214 226.7v427.6l298 232.2l298-232.2V226.7L512 125.1L214 226.7zM632.8 328H688c6.5 0 10.3 7.4 6.5 12.7L481.9 633.4a16.1 16.1 0 0 1-26 0l-126.4-174c-3.8-5.3 0-12.7 6.5-12.7h55.2c5.2 0 10 2.5 13 6.6l64.7 89.1l150.9-207.8c3-4.1 7.9-6.6 13-6.6z"/><path fill="currentColor" d="M404.2 453.3c-3-4.1-7.8-6.6-13-6.6H336c-6.5 0-10.3 7.4-6.5 12.7l126.4 174a16.1 16.1 0 0 0 26 0l212.6-292.7c3.8-5.3 0-12.7-6.5-12.7h-55.2c-5.1 0-10 2.5-13 6.6L468.9 542.4l-64.7-89.1z"/></svg>
                                    </div>
                                    <div className="grow text-center md:text-left py-4">
                                        <p className="font-bold text-[#333] mb-2">Design Review Consultant</p>
                                        <p className="text-[#666] text-semibold">Sophisticated Threat Modeling for app so that you get your security right before even writing a single line of code.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-3 md:col-start-2 md:col-span-1">
                                <div className="md:flex h-full items-center gap-4 p-4">
                                    <div className="opacity-50 text-center mb-4 md:mb-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="inline-block" aria-hidden="true" role="img" width="8em" height="8em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
                                            <path fill="currentColor" d="M13 9h5.5L13 3.5V9M6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m5 2H6v16h12v-9h-7V4m2 12v-1.5c0-1.4-1.4-2.5-2.7-2.5c-1.4 0-2.3 1.1-2.3 2.5V16h-1v6h7v-6h-1m-1 0h-3v-1.5c0-.8.7-1.3 1.5-1.3s1.5.5 1.5 1.3V16Z"/>
                                        </svg>
                                    </div>
                                    <div className="grow text-center md:text-left py-4">
                                        <p className="font-bold text-[#333] mb-2">AI Security Lab</p>
                                        <p className="text-[#666] text-semibold">
                                            "What happens when an LLM is given the same resources and tools as a human security researcher?"
                                            <a 
                                                href="https://electrovolt.io" 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className="block mt-2 text-cyan-500 hover:text-cyan-600"
                                            >
                                                Learn more →
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
 
                    <div className="pt-32 text-center">
                        <h2 className="text-2xl md:text-2xl font-bold uppercase text-[#333]">By The team that helped secure</h2>
                        <span className="inline-block w-24 border-t border-gray-400"></span>
                        <div className="relative overflow-hidden w-full">
                            <div className="flex animate-scroll">
                                <div className="flex shrink-0 gap-4 mx-4">
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="/images/google.jpeg" className="w-20 md:w-24 object-contain"/>
                                    </div>
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="/images/apple.jpeg" className="w-20 md:w-24 object-contain"/>
                                    </div>
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="/images/facebook.png" className="w-20 md:w-24 object-contain"/>
                                    </div>  
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="/images/dropbox.svg" className="w-20 md:w-24 object-contain"/>
                                    </div>
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="/images/paypal.jpeg" className="w-20 md:w-24 object-contain"/>
                                    </div>
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="/images/discord-logo.png" className="w-20 md:w-24 object-contain"/>
                                    </div>
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="/images/microsoft.svg" className="w-20 md:w-24 object-contain"/>
                                    </div>
                                </div>
                                <div className="flex shrink-0 gap-4 mx-4">
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg" className="w-16 md:w-20 object-contain"/>
                                    </div>
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/c/ce/X_logo_2023.svg" className="w-8 md:w-10 object-contain"/>
                                    </div>
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/a/ae/Steam_logo.svg" className="w-16 md:w-20 object-contain"/>
                                    </div>  
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/0/0e/Shopify_logo_2018.svg" className="w-20 md:w-24 object-contain"/>
                                    </div>
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg" className="w-8 md:w-10 object-contain"/>
                                    </div>
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/9/9c/Vimeo_Logo.svg" className="w-20 md:w-24 object-contain"/>
                                    </div>
                                    <div className="p-2 h-16 border-2 border-transparent flex items-center">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/c/c6/Monday_logo.svg" className="w-20 md:w-24 object-contain"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-32 text-center">
                        <h2 className="text-2xl md:text-2xl font-bold uppercase text-[#333]">Our team</h2>
                        <span className="inline-block w-24 border-t border-gray-400"></span>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 max-w-4xl mx-auto gap-8 my-8">
                            <div className="p-4">
                                <p className="text-[#333] text-lg font-semibold mb-2">Mohan Pedhapati</p>
                                <div className="flex justify-center gap-3">
                                    <a href="https://github.com/msrkp" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12Z" clip-rule="evenodd"/></svg>
                                    </a>
                                    <a href="https://twitter.com/s1r1u5_" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z"/></svg>
                                    </a>
                                </div>
                            </div>
                            <div className="p-4">
                                <p className="text-[#333] text-lg font-semibold mb-2">Luan Herrera</p>
                                <div className="flex justify-center gap-3">
                                    <a href="https://github.com/lbherrera" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12Z" clip-rule="evenodd"/></svg>
                                    </a>
                                    <a href="https://twitter.com/lbherrera_" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z"/></svg>
                                    </a>
                                </div>
                            </div>
                            <div className="p-4">
                                <p className="text-[#333] text-lg font-semibold mb-2">Maciej Piechota</p>
                                <div className="flex justify-center gap-3">
                                    <a href="https://github.com/haqpl" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12Z" clip-rule="evenodd"/></svg>
                                    </a>
                                    <a href="https://twitter.com/haqpl" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z"/></svg>
                                    </a>
                                </div>
                            </div>
                            <div className="p-4">
                                <p className="text-[#333] text-lg font-semibold mb-2">Caio Luders</p>
                                <div className="flex justify-center gap-3">
                                    <a href="https://github.com/caioluders" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12Z" clip-rule="evenodd"/></svg>
                                    </a>
                                    <a href="https://twitter.com/caioluders" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z"/></svg>
                                    </a>
                                </div>
                            </div>
                            <div className="p-4">
                                <p className="text-[#333] text-lg font-semibold mb-1">Ahmed Kahla</p>
                                <p className="text-[#666] text-sm mb-3">Security Researcher</p>
                                <div className="flex justify-center gap-3">
                                    <a href="https://github.com/kahla-sec" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12Z" clip-rule="evenodd"/></svg>
                                    </a>
                                    <a href="https://x.com/BelkahlaAhmed1" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z"/></svg>
                                    </a>
                                </div>
                            </div>
                            <div className="p-4">
                                <p className="text-[#333] text-lg font-semibold mb-1">Larry Yuan</p>
                                <p className="text-[#666] text-sm mb-3">Security Researcher</p>
                                <div className="flex justify-center gap-3">
                                    <a href="https://x.com/ehhthing" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12Z" clip-rule="evenodd"/></svg>
                                    </a>
                                    <a href="https://github.com/ehhthing" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z"/></svg>
                                    </a>
                                </div>
                            </div>
                            <div className="p-4">
                                <p className="text-[#333] text-lg font-semibold mb-1">Darin Mao</p>
                                <p className="text-[#666] text-sm mb-3">Security Researcher</p>
                                <div className="flex justify-center gap-3">
                                    <a href="https://github.com/DarinMao" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12Z" clip-rule="evenodd"/></svg>
                                    </a>
                                    <a href="https://x.com/darinmao_" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z"/></svg>
                                    </a>
                                </div>
                            </div>
                            <div className="p-4">
                                <p className="text-[#333] text-lg font-semibold mb-1">Harsh Jaiswal</p>
                                <p className="text-[#666] text-sm mb-3">Security Researcher</p>
                                <div className="flex justify-center gap-3">
                                    <a href="https://github.com/rootxharsh" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12Z" clip-rule="evenodd"/></svg>
                                    </a>
                                    <a href="https://x.com/rootxharsh" className="text-[#888] hover:text-[#333] transition-colors">
                                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.2rem" height="1.2rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z"/></svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-32 text-center" id="contact">
                        <h2 className="text-2xl md:text-2xl font-bold uppercase text-[#333]">Contact us</h2>
                        <span className="inline-block w-24 border-t border-gray-400"></span>
                        <div className="my-4 text-left">
                            <div className="py-4 ">
                                <p className="text-[#333] text-xl font-semibold">Business enquiries</p>
                                <p className="text-[#333] ">Want to supercharge your application's security to 100% 🔋 ? Reach out to us ...</p>
                            </div>
                            <div className="py-4">
                                <div>
                                    <a href="mailto:hello@electrovolt.io" className="text-[#333] font-semibold inline-flex">
                                        <svg className="inline mr-2" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.5em" height="" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36"><path fill="currentColor" d="M32 6H4a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2Zm-1.54 22H5.66l7-7.24l-1.44-1.39L4 26.84V9.52l12.43 12.37a2 2 0 0 0 2.82 0L32 9.21v17.5l-7.36-7.36l-1.41 1.41ZM5.31 8h25.07L17.84 20.47Z" class="clr-i-outline clr-i-outline-path-1"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                                        hello@electrovolt.io
                                    </a>
                                </div>
                             
                                <div className="mt-8 text-[#333]">
                                    <p className="text-[#333] mb-2">Follow us on: </p>
                                    <a href="https://twitter.com/ElectrovoltSec" className="text-[#888] hover:text-[#333] hover:scale-125 mr-4 inline-block">
                                        <svg className="inline-block" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2rem" height="" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z"/></svg>
                                    </a>
                                    
                                    <a href="https://github.com/ElectrovoltSec" className="text-[#888] hover:text-[#333] hover:scale-125  inline-block">
                                        <svg className="inline-block" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2rem" height="" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M12 0C5.37 0 0 5.37 0 12c0 5.31 3.435 9.795 8.205 11.385c.6.105.825-.255.825-.57c0-.285-.015-1.23-.015-2.235c-3.015.555-3.795-.735-4.035-1.41c-.135-.345-.72-1.41-1.23-1.695c-.42-.225-1.02-.78-.015-.795c.945-.015 1.62.87 1.845 1.23c1.08 1.815 2.805 1.305 3.495.99c.105-.78.42-1.305.765-1.605c-2.67-.3-5.46-1.335-5.46-5.925c0-1.305.465-2.385 1.23-3.225c-.12-.3-.54-1.53.12-3.18c0 0 1.005-.315 3.3 1.23c.96-.27 1.98-.405 3-.405s2.04.135 3 .405c2.295-1.56 3.3-1.23 3.3-1.23c.66 1.65.24 2.88.12 3.18c.765.84 1.23 1.905 1.23 3.225c0 4.605-2.805 5.625-5.475 5.925c.435.375.81 1.095.81 2.22c0 1.605-.015 2.895-.015 3.3c0 .315.225.69.825.57A12.02 12.02 0 0 0 24 12c0-6.63-5.37-12-12-12Z" clip-rule="evenodd"/></svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                  

                    <br/>
                    <br/>
                    <br/>
                </div>
                
            </div>
            <Footer/>
        </div>
        
     );
}

export default Header;
