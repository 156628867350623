import { useState } from "react";

function Navbar() {
    const [toggleNavbar, setToggleNavbar] = useState("max-h-0");
    const [navcolorChange, setNavColorchange] = useState(false);

    const changeNavbarColor = () =>{
        if(window.scrollY >= 500){
            setNavColorchange(true);
        }
        else{
            setNavColorchange(false);
        }
    };

    window.addEventListener('scroll', changeNavbarColor);

    const handleNavbarToggle = () => {
        console.log(toggleNavbar)
        setToggleNavbar((prevstate) => {
            if(prevstate === "max-h-0")
                return "max-h-64";
            else if(prevstate === "max-h-64")
                return "max-h-0";
        });
    }

    return ( 
        <div className={navcolorChange ? "bg-[#444] shadow-md fixed w-full transition-all z-10 " : "bg-[#333] shadow fixed w-full transition-all z-10 "}>
            <div className="md:flex justify-between items-center container mx-auto">
                <div className="flex md:block justify-between items-center px-4">
                    <p className={navcolorChange ? "text-cyan-200 inline-flex items-center gap-2 py-5 text-xl font-semibold" : "text-cyan-200 inline-flex items-center gap-2  py-6 text-xl font-semibold"}>
                            <img src="/images/logo-cover.png" className="inline-block w-8"/>
                        
                            <span>Electrovolt</span>
                    </p>
                    <button className="md:hidden text-white" onClick={handleNavbarToggle}>
                        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                    </button>
                </div>

                <div className={"box-border overflow-hidden md:h-auto transition-all md:max-h-fit "+toggleNavbar }>
                    <ul className="border-t  md:border-t-0 border-white/50 md:flex items-center text-white font-medium py-2 md:py-0 ">
                        <li className=""><a href="#" className="px-4 py-2 md:py-1 hover:bg-[#AAA]/50 md:hover:text-cyan-400 md:hover:bg-transparent block">Home</a></li>
                        <li className=""><a href="#about" className="px-4 py-2 md:py-1 hover:bg-[#AAA]/50 md:hover:text-cyan-400 md:hover:bg-transparent block">About</a></li>
                        <li className=""><a href="#services" className="px-4 py-2 md:py-1 hover:bg-[#AAA]/50 md:hover:text-cyan-400 md:hover:bg-transparent block">Services</a></li>
                        <li className=""><a href="#contact" className="px-4 py-2 md:py-1 hover:bg-[#AAA]/50 md:hover:text-cyan-400 md:hover:bg-transparent block">Contact</a></li>
                        <li className=""><a href="https://blog.electrovolt.io/" target="_blank" className="px-4 py-2 md:py-1 hover:bg-[#AAA]/50 md:hover:text-cyan-400 md:hover:bg-transparent block">Blog</a></li>
                        <li className=""><a href="https://electrovolt.io" target="_blank" rel="noopener noreferrer" className="px-4 py-2 md:py-1 hover:bg-[#AAA]/50 md:hover:text-cyan-400 md:hover:bg-transparent block">AI Lab</a></li>
                    </ul>
                </div>
            </div>
        </div>
     );
}

export default Navbar;